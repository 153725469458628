$(function(){

  $('a[href^="#"]').click(function(){
    var height = $(".header-wrap__logo ").innerHeight();
    var speed = 600;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top - height;
    $('body,html').animate({scrollTop:position}, speed, 'swing');

    return false;
  });

  $(".anker").click(function(){
    bodyScrollLock.clearAllBodyScrollLocks();
    $(".header-wrap__menu").removeClass("active");
    if ($(".menu-tab").text() === 'CLOSE') {
      $(".menu-tab").text('MENU');
    } else {
      $(".menu-tab").text('CLOSE');
    }
    $(".header-menu").fadeToggle(300);

  });

  $(".fadeIn_up").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).stop().addClass("is-show");
    }
  });
  $(".fadeIn_up_repeat").on("inview", function (event, isInView) {
    if(isInView){
      $(this).stop().addClass("is-show");
    }else{
      $(this).stop().removeClass("is-show");
    }
  });

  let menu = $(".header-menu");

  $(".header-wrap__menu").click(function () {
    $(this).toggleClass('active');
    menu.fadeToggle(300);
    if ($(".menu-tab").text() === 'CLOSE') {
      $(".menu-tab").text('MENU');
      bodyScrollLock.clearAllBodyScrollLocks();
    } else {
      bodyScrollLock.disableBodyScroll(menu);
      $(".menu-tab").text('CLOSE');
    }
  });

  $(".list-item__body").hide(); 
  //アコーディオン
  $('.faq-wrap__list .list-item__head').click(function () {
    $(this).next('div').slideToggle();
    $(this).find(".icon").toggleClass('open');
  });
});